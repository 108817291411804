/*#region col*/
.col100 {
	width: 100% !important;
}

.col90 {
	width: 90% !important;
}

.col85 {
	width: 85% !important;
}

.col80 {
	width: 80% !important;
}

.col75 {
	width: 75% !important;
}

.col70 {
	width: 70% !important;
}

.col66 {
	width: 66.6666% !important;
}

.col65 {
	width: 65% !important;
}

.col60 {
	width: 60% !important;
}

.col55 {
	width: 55% !important;
}

.col50 {
	width: 50% !important;
}

.col45 {
	width: 45% !important;
}

.col40 {
	width: 40% !important;
}

.col35 {
	width: 35% !important;
}

.col30 {
	width: 30% !important;
}

.col33 {
	width: 33.3333% !important;
}

.col25 {
	width: 25% !important;
}

.col20 {
	width: 20% !important;
}

.col16 {
	width: 16.6666667% !important;
}

.col15 {
	width: 15% !important;
}
/*#endregion col*/

/*#region w*/
.w1920{
	width: 100% !important;
	max-width: 1920px !important;
	margin: 0 auto;
}
.w1200{
	width: 100% !important;
	max-width: 1200px !important;
	margin: 0 auto;
}
.w1100{
	width: 100% !important;
	max-width: 1100px !important;
	margin: 0 auto;
}
.w1000{
	width: 100% !important;
	max-width: 1000px !important;
	margin: 0 auto;
}
.w800{
	width: 70% !important;
	max-width: 800px !important;
	margin: 0 auto;
}
/*#endregion w*/

/*#region m*/
.m-0{
	margin: 0 !important;
}
.mt-0{
	margin-top: 0 !important;
}
.mb-0{
	margin-bottom: 0 !important;
}
.mr-0{
	margin-right: 0 !important;
}
.ml-0{
	margin-left: 0 !important;
}
.m-10{
	margin: 10px !important;
}
.mtb-10{
	margin: 10px 0 !important;
}
.mlr-10{
	margin: 0 10px !important;
}
.mt-10{
	margin-top: 10px !important;
}
.mb-10{
	margin-bottom: 10px !important;
}
.mr-10{
	margin-right: 10px !important;
}
.ml-10{
	margin-left: 10px !important;
}
.m-15{
	margin: 15px !important;
}
.mtb-15{
	margin: 15px 0 !important;
}
.mlr-15{
	margin: 0 15px !important;
}
.mt-15{
	margin-top: 15px !important;
}
.mb-15{
	margin-bottom: 15px !important;
}
.mr-15{
	margin-right: 15px !important;
}
.ml-15{
	margin-left: 15px !important;
}
/*#endregion m*/

/*#region p*/
.p-0{
	padding: 0 !important;
}
.pt-0{
	padding-top: 0 !important;
}
.pb-0{
	padding-bottom: 0 !important;
}
.pr-0{
	padding-right: 0 !important;
}
.pl-0{
	padding-left: 0 !important;
}
.p-10{
	padding: 10px !important;
}
.ptb-10{
	padding: 10px 0 !important;
}
.plr-10{
	padding: 0 10px !important;
}
.pt-10{
	padding-top: 10px !important;
}
.pb-10{
	padding-bottom: 10px !important;
}
.pr-10{
	padding-right: 10px !important;
}
.pl-10{
	padding-left: 10px !important;
}
.p-15{
	padding: 15px !important;
}
.ptb-15{
	padding: 15px 0 !important;
}
.plr-15{
	padding: 0 15px !important;
}
.pt-15{
	padding-top: 15px !important;
}
.pb-15{
	padding-bottom: 15px !important;
}
.pr-15{
	padding-right: 15px !important;
}
.pl-15{
	padding-left: 15px !important;
}
/*#endregion p*/

/*#region font*/
.bold{
    font-weight: 500;
}
/*#endregion font*/

/*#region common*/
.flex{
	display: flex !important;
	flex-wrap: wrap !important;
	&.no-warp{
		flex-wrap: nowrap !important;
	}
	&.column{
		flex-direction: column !important;
	}
	&.center {
		justify-content: center !important;
	}
	&.right{
		justify-content: flex-end !important;
	}
	&.between{
		justify-content: space-between !important;
	}
	&.middle{
		align-items: center !important;
	}
	&.bottom{
		align-items: flex-end !important;
	}
}
/*#endregion common*/
