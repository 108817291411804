.pageContainer{
	max-width: 768px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 60px !important;
}
.mat-progress-spinner{
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%,-50%) !important;
}
.mat-progress-spinner circle, .mat-spinner circle{
	stroke: #aaa !important;
}
.mat-paginator-page-size-label{
	font-size: 14px !important;
    transform: translateY(10px) !important;
	@media (max-width:380px){
		font-size: 12px !important;
	}
}
.mat-paginator-range-label{
	font-size: 14px !important;
    transform: translateY(3px) !important;
	@media (max-width:380px){
		font-size: 12px !important;
		margin: 0 10px !important;
	}
}
.mat-tab-group{
	margin-bottom: 10px;
}
.mat-tab-label{
	width: 33.33%;
}