
/* ----------Scrollbar Initial---------- */
::-webkit-scrollbar {
	-webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
	width: 4px;
	background-color: #A0A0A0;
}

::-webkit-scrollbar:horizontal {
	width: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #f5f5f5;
}

/* ----------Initial Setting---------- */
html{
	font-size: 16px;
	height: 100%;
	overflow-x: hidden;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font: inherit;
	color: inherit;
}

body {
	font-family: sans-serif;
	color: #000000;
	background-color: #ffffff;
	overflow-x: hidden;
}

@media screen and (max-width: 480px) {
	html {
		font-size: 16px ;
	}
}

a:link,
a:visited,
a:hover,
a:active {
	text-decoration: none;
}

/* ----------text---------- */
h1 {
	color: #000000;
	line-height: 1.6;
	font-family: 'Noto Sans TC', sans-serif;
	font-weight: 300;
	font-size: 28px;
}
h2 {
	color: #000000;
	line-height: 1.6;
	font-family: 'Noto Sans TC', sans-serif;
	font-weight: 300;
	font-size: 26px;
}
h3 {
	color: #000000;
	line-height: 1.6;
	font-family: 'Noto Sans TC', sans-serif;
	font-weight: 300;
	font-size: 24px;
}
h4{
	color: #000000;
	line-height: 1.6;
	letter-spacing: 5px;
	font-family: 'Noto Sans TC', sans-serif;
	font-weight: 300;
	font-size: 22px;
}
h5{
	color: #000000;
	line-height: 1.6;
	letter-spacing: 0px;
	font-family: 'Noto Sans TC', sans-serif;
	font-weight: 300;
	font-size: 20px;
}
h6{
	color: #000000;
	line-height: 1.6;
	letter-spacing: 0px;
	font-family: 'Noto Sans TC', sans-serif;
	font-weight: 300;
	font-size: 18px;
}
p,
ul,
li,
label,
input,
textarea
{
	color: #000000;
	line-height: 1.6;
	font-family: 'Noto Sans TC', sans-serif;
	letter-spacing: 0px;
	font-weight: 300;
	font-size: 16px;
}
a,span,strong{
	font-family: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	color: inherit;
	line-height: inherit;
}
strong{
	font-weight: 500;
}
small{
	color: #000000;
	line-height: 1.6;
	font-family: 'Noto Sans TC', sans-serif;
	font-weight: 300;
	font-size: 14px;
}
input,
textarea{
	padding: 0 10px;
}
textarea{
	padding-top: 10px;
}
